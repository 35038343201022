<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>行銷應用中心</b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'RewardCardList' }">集點卡管理</b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'RewardMissionList', params: { reward_card_id: rewardCardId} }">集點任務</b-breadcrumb-item>
            <b-breadcrumb-item active>點數紀錄</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <PointLog :show-filter="false" upper-target-type="RewardMission" :upper-target-id="rewardMissionId" :prefix-file-name="rewardMission.title" />
    </b-card>
  </div>
</template>

<script>
import PointLog from "@/components/Dashboard/PointLog/PointLog.vue";
import rewardMissionApi from "@/apis/reward-mission";

export default {
  components: {
    PointLog,
  },
  computed: {
    rewardCardId() {
      return this.$route.params.reward_card_id;
    },
    rewardMissionId() {
      return this.$route.params.reward_mission_id;
    },
  },
  data() {
    return {
      rewardMission: {},
    }
  },
  mounted() {
    this.fetchRewardMission();
  },
  methods: {
    async fetchRewardMission() {
      const { data } = await rewardMissionApi.get(this.rewardCardId, this.rewardMissionId);

      this.rewardMission = data.data;
    },
  },
};
</script>
